import React, { useEffect } from "react";
import "./section3.scss";
import Phone1 from "../../assets/imgs/double-screens.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Section3 = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='section3'>
      <div className='section3-container container'>
        <div className='left'>
          <ul>
            <li data-aos='fade-right'>
              <p className='elment'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </li>
            <li data-aos='fade-right'>
              <p className='elment'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </li>
            <li data-aos='fade-right'>
              <p className='elment'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </li>
            <li data-aos='fade-right'>
              <p className='elment'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </li>
          </ul>
        </div>
        <div className='right'>
          <img src={Phone1} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Section3;
