import React, { useEffect } from "react";
import "./section1.scss";
import Navbar from "../navbar/Navbar.jsx";
import Mobile from "../../assets/imgs/Group 452236.png";
import DownloadButton from "../downloadButton/DownloadButton";
import Google from "../../assets/imgs/google-play.png";
import Apple from "../../assets/imgs/apple.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Section1 = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='section1'>
      <Navbar value={false} />
      <div className='section1-container container'>
        <div data-aos='fade-right' className='left'>
          <div className='content'>
            <div className='texts'>
              <h1>Need to know where you are going out ?</h1>
              <p>
                Step out is your guide to have
                <br /> a great day in Bahrain
              </p>
            </div>
            <div className='buttons'>
              <DownloadButton value={true} logo={Google} text='Play Store' />
              <DownloadButton value={true} logo={Apple} text='App Store' />
            </div>
          </div>
        </div>
        <div data-aos='fade-left' className='right'>
          <img data-aos='flip-right' src={Mobile} alt='' />
        </div>
      </div>
    </section>
  );
};

export default Section1;
