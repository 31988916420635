import React, { useEffect } from "react";
import "./footer.scss";
import DownloadButton from "../downloadButton/DownloadButton.jsx";
import Google from "../../assets/imgs/google-play.png";
import Apple from "../../assets/imgs/apple.png";
import Facebook from "../../assets/imgs/facebook.png";
import Instagram from "../../assets/imgs/instagram.png";
import Photo from "../../assets/imgs/[fpdl.in]_abu-dhabi-buildings-skyline-from-sea_70898-2538_large.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
const Footer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  let date = new Date();
  let year = date.getFullYear();
  return (
    <section className='footer'>
      <div className='gradiant'></div>
      <div className='footer-container container'>
        <div className='texts'>
          <h1 data-aos='fade-up'>
            Are you ready to get out or know anything in Bahrain?
          </h1>
          <h4 data-aos='fade-up'>Download STEP OUT</h4>
          <p data-aos='fade-up'>
            Step out is available for all devices / Lorem Ipsum is simply dummy
            text of the printing and typesetting industry. Lorem Ipsum has been
            the industry's standard dummy text ever since the 1500s
          </p>
        </div>
        <div data-aos='fade-up' className='buttons'>
          <DownloadButton value={false} logo={Google} text='Play Store' />
          <DownloadButton value={false} logo={Apple} text='App Store' />
        </div>
        <div className='links'>
          <div className='iconsLinks'>
            <a href=''>
              {" "}
              <img src={Facebook} alt='' className='icons' />
            </a>
            <a href=''>
              <img src={Instagram} alt='' className='icons' />
            </a>
          </div>
        </div>
      </div>
      <div className='copyrights'>
        &copy; Copyrights {year} E Brand Agency All rights reserved.
      </div>
      <div data-aos='fade-up' className='layout'>
        <img src={Photo} alt='' />
      </div>
    </section>
  );
};

export default Footer;
