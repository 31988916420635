import React from "react";
import "./downloadButton.scss";
const DownloadButton = (props) => {
  return (
    <div className={props.value ? "downloadButton" : "downloadButton black"}>
      <div className='leftButton'>
        <img src={props.logo} alt='' />
      </div>
      <div className='rightButton'>
        <p>Download on the</p>
        <h3>{props.text}</h3>
      </div>
    </div>
  );
};

export default DownloadButton;
