import React, { useEffect } from "react";
import "./section1AboutUs.scss";
import Navbar from "../navbar/Navbar";
import stepout from "../../assets/imgs/stepout-aboutus.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Section1AboutUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='section1-aboutsus'>
      <Navbar value={true} />
      <div className='section1-aboutsus-container container'>
        <img data-aos='fade-right' src={stepout} alt='' />
        <p data-aos='fade-up'>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries
        </p>
      </div>
    </section>
  );
};

export default Section1AboutUs;
